<template>
  <div class="category-settings">
    <div class="form-group row">
      <label class="col-12 col-form-label" for="input-title">Meta title</label>
      <div class="col-12">
        <input type="text" v-model="data.meta_title" id="input-title" name="title" 
        class="form-control" v-validate="'max:200'" :class="{ 'is-invalid': errors.has('title') }"
         placeholder="e.g: Logo Design | company name" />
        <span class="text-danger invalid-feedback"  v-show="errors.has('title')">{{ errors.first('title') }}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-form-label" for="input-meta-keys">Meta keys</label>
      <div class="col-12">
        <input type="text" v-model="data.meta_keys" id="input-meta-keys" name="meta-keys" class="form-control" v-validate="'max:200'" :class="{ 'is-invalid': errors.has('meta-keys') }" placeholder="e.g : Logo Design, Letterhead, Poster Design" />
        <span class="text-danger invalid-feedback"  v-show="errors.has('meta-keys')">{{ errors.first('meta-keys') }}</span>
      </div>
    </div>
    <div class="form-group row mt-3">
      <label class="col-12 col-form-label" for="text-area-meta-desc">Meta Description</label>
      <div class="col-12">
        <textarea id="text-area-meta-desc" v-validate="'max:1000'" v-model="data.meta_desc" class="form-control" rows="3" name="meta-desc" placeholder="Write Meta Description"></textarea>
         <span class="text-danger invalid-feedback"  v-show="errors.has('meta-desc')">{{ errors.first('meta-desc') }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="">Category Description</label>
        <quill-editor v-model="data.description" style="min-height: 100px;" :options="editorOption" />
      </div>
    </div>
    <div class="d-flex mt-3 justify-content-end">
      <b-button variant="primary" @click.prevent="updateCategory()" type="button">Update</b-button>
    </div>
  </div>
</template>

<script>

export default {

  data() {
    return {
      data: {},
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            // [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            // [{ 'font': [] }],
            // [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
            // ['link', 'image']
          ],
        }
      },
    }
  },
  computed: {
    category(){
      return this.$store.state.categoryList.category
    }
  },
  methods: {
    updateCategory() {
      this.$validator.validateAll().then(result => {
        if (!result) {this.alertError("Form not valid"); return}
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/categories/${this.$route.params.categoryId}/update-desc`, formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$store.commit("categoryList/UPDATE_DESCRIPTION", response.data.data.description)
          }
        })
      })
    },
    patchCategory(){
      let description = this.category.description
      if(description){
        this.data = {
          description: description.description,
          meta_title: description.meta_title,
          meta_keys: description.meta_keys,
          meta_desc: description.meta_desc,
        }
      }
    },
  },
  mounted(){
    this.patchCategory()
  }
}
</script>
