<template>
  <div class="category-products">
    <div class="row">
      <div class="col-12">
        <ul class="list-group">
          <li class="list-group-item d-flex cursor-pointer align-items-center justify-content-between"
            v-for="(product, index) in products" :key="index" @click="navigateTo(product.id)">
            <span class="font-weight-bolder"><i class="mdi mdi-star-four-points-outline mr-1"></i>
            {{product.name}} - ${{product.price}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default{
  computed: {
    products(){
      return this.$store.state.categoryList.category.products.length ?
          this.$store.state.categoryList.category.products : []
    }
  },
  methods: {
    navigateTo(id){
      this.$router.push({path: `/products/${id}`});
    }
  }
}
</script>
